// 百度地图api初始化
export default function getBMap () {
  const ak = 'KinmHv4uzaZ53wCZAETkVKxttISIFg5S' // 百度地图开发者key
  // const ak = '73Lo1Hy3hbsmxif3qTBIGN2GbL9Wqbyt' // 百度地图开发者key
  return new Promise((resolve, reject) => {
    const BMap = window.BMap
    if (BMap) { // 已经加载成功，后续使用不需要重复下面的js挂载
      resolve(BMap)
      return
    }
    window.onBMapCallback = function () { // 加载成功后，执行回调
      resolve(window.BMap)
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
    script.onerror = reject
    document.head.appendChild(script)
  })
}
